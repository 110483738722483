import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getListings, Listing } from "../api/api";

export const fetchListings = createAsyncThunk(
  "sessions/fetchListings",
  async () => {
    const listings = await getListings();
    return listings;
  }
);

interface ListingsState {
  status: string;
}

const listingsInitialState: ListingsState = {
  status: "idle",
};

const listingsAdapter = createEntityAdapter<Listing>({
  selectId: (listing) => listing.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const slice = createSlice({
  name: "listings",
  initialState: listingsAdapter.getInitialState(listingsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchListings.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchListings.fulfilled,
      (state, { payload }: PayloadAction<Listing[]>) => {
        listingsAdapter.setAll(state, payload);
        state.status = "succeeded";
      }
    );
    builder.addCase(fetchListings.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {
  selectAll: selectAllListings,
  selectById: selectListingsById,
  selectIds: selectListingsIds,
} = listingsAdapter.getSelectors<any>((state) => state.listings);

export default slice.reducer;
