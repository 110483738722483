import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Header } from "./Header";
import { RootState } from "../store";
import * as yup from "yup";
import { addNewSession } from "../store/sessionsSlice";
import { selectAllListings, fetchListings } from "../store/listingsSlice";
import { selectAllEvents, fetchEvents } from "../store/eventsSlice";
import { ApiResponse } from "./ApiRepsonse";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
export default function SessionCreate() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = React.useState<string>("");
  const onTypeChanged = (e) => setType(e.target.value);

  const schema = yup.object().shape({
    name: yup.string().min(2).max(200).required(),
    type: yup.string().required(),
    listing_id: yup.string().when("type", {
      is: "listing",
      then: yup.string().required(),
    }),
    people: yup.number().when("type", {
      is: "listing",
      then: yup.number().min(1).max(9).required(),
    }),
    event_id: yup.string().when("type", {
      is: "event",
      then: yup.string().required(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const listings = useSelector((state) => selectAllListings(state));
  const listingsStatus = useSelector(
    (state: RootState) => state.listings.status
  );
  const events = useSelector((state) => selectAllEvents(state));
  const eventStatus = useSelector((state: RootState) => state.events.status);

  useEffect(() => {
    if (type === "listing") {
      dispatch(fetchListings());
    }
    if (type === "event") {
      dispatch(fetchEvents());
    }
  }, [type]);

  const onSubmit = async (form) => {
    await dispatch(
      addNewSession({
        name: form.name,
        type: form.type,
        listing_id: form.type === "listing" ? form.listing_id : null,
        people: form.type === "listing" ? form.people : null,
        event_id: form.type === "event" ? form.event_id : null,
      })
    );
    history.push("/session");
  };

  return (
    <>
      <Header title="Créer une session" prevRoute="../" />
      <Container fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="name">Nom de la session</Label>
            <Input
              {...register("name")}
              id="name"
              invalid={errors && errors.name}
            />
            {errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
          </FormGroup>

          <FormGroup>
            <Label for="type">Liste d'invités</Label>
            <Input
              {...register("type")}
              type="select"
              invalid={errors && errors.type}
              onChange={onTypeChanged}
            >
              <option></option>
              <option value="listing">à partir d'une liste SendInBlue</option>
              <option value="event">
                à partir d'un évenement VIP Art-o-rama
              </option>
            </Input>
            {errors.type && <FormFeedback>{errors.type?.message}</FormFeedback>}
          </FormGroup>

          {type === "listing" && (
            <>
              {listingsStatus !== "succeeded" ? (
                <div className="text-center">
                  <ApiResponse status={listingsStatus} />
                </div>
              ) : (
                <>
                  <FormGroup>
                    <Label for="listing_id">Liste SendInBlue</Label>
                    <Input
                      type="select"
                      {...register("listing_id")}
                      invalid={errors && errors.listing_id}
                    >
                      <option></option>
                      {listings.map((listing: any) => (
                        <option key={listing.id} value={listing.id}>
                          {listing.name}
                        </option>
                      ))}
                    </Input>
                    {errors.listing_id && (
                      <FormFeedback>{errors.listing_id?.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="people">Nombre de personnes par entrée</Label>
                    <Input
                      type="number"
                      {...register("people")}
                      invalid={errors && errors.people}
                    ></Input>
                    {errors.people && (
                      <FormFeedback>{errors.people?.message}</FormFeedback>
                    )}
                  </FormGroup>
                </>
              )}
            </>
          )}

          {type === "event" && (
            <>
              {eventStatus !== "succeeded" ? (
                <div className="text-center">
                  <ApiResponse status={listingsStatus} />
                </div>
              ) : (
                <FormGroup>
                  <Label for="event_id">Événement Art-o-rama</Label>
                  <Input
                    type="select"
                    {...register("event_id")}
                    invalid={errors && errors.event_id}
                  >
                    <option></option>
                    {events.map((event: any) => (
                      <option key={event.id} value={event.id}>
                        {event.name} ({event.date})
                        {/* {event.name} ({event.total_subscribers}) */}
                      </option>
                    ))}
                  </Input>
                  {errors.event_id && (
                    <FormFeedback>{errors.event_id?.message}</FormFeedback>
                  )}
                </FormGroup>
              )}
            </>
          )}

          <Button color="primary" block type="submit">
            Créer
          </Button>
        </Form>
      </Container>
    </>
  );
}
