import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  getSubscribers,
  getSyncSubscribers,
  postSubscriberDirection,
  Subscriber,
} from "../api/api";

export const fetchSubscribers = createAsyncThunk(
  "sessions/fetchSubscribers",
  async (sessionId: string) => {
    const subscribers = await getSubscribers(sessionId);
    return subscribers;
  }
);

export const syncSubscribers = createAsyncThunk(
  "sessions/syncSubscribers",
  async (sessionId: string) => {
    return await getSyncSubscribers(sessionId);
  }
);

export const setSubscriberDirection = createAsyncThunk(
  "sessions/subscriberDirection",
  async ({ sessionId, subscriberId, direction }: any) => {
    var subscriber = await postSubscriberDirection(
      sessionId,
      subscriberId,
      direction
    );

    const people_in = subscriber.people_in;
    const people_out = subscriber.people_out;
    return { id: subscriberId, changes: { people_in, people_out } };
  }
);

interface SubscribersState {
  status: string;
}

const subscribersInitialState: SubscribersState = {
  status: "idle",
};

const subscribersAdapter = createEntityAdapter<Subscriber>({
  selectId: (subscriber) => subscriber.id,
  //sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const slice = createSlice({
  name: "subscribers",
  initialState: subscribersAdapter.getInitialState(subscribersInitialState),
  reducers: {
    // subscriberUpdated: subscribersAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscribers.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchSubscribers.fulfilled,
      (state, { payload }: PayloadAction<Subscriber[]>) => {
        subscribersAdapter.setAll(state, payload);
        state.status = "succeeded";
      }
    );
    builder.addCase(fetchSubscribers.rejected, (state) => {
      state.status = "failed";
    });

    builder.addCase(syncSubscribers.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(syncSubscribers.fulfilled, (state) => {
      state.status = "succeeded";
    });

    builder.addCase(syncSubscribers.rejected, (state) => {
      state.status = "failed";
    });

    builder.addCase(setSubscriberDirection.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(setSubscriberDirection.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      subscribersAdapter.updateOne(state, {
        id: payload.id,
        changes: payload.changes,
      });
    });
    builder.addCase(setSubscriberDirection.rejected, (state) => {
      state.status = "failed";
    });
  },
});

const stateSubscribers = (state) => state.subscribers;

export const {
  selectAll: selectAllSubscribers,
  selectEntities: selectEntitiesSubscribers,
  selectTotal: selectTotalSubscribers,
  selectById: selectSubscribersById,
  selectIds: selectSubscribersIds,
} = subscribersAdapter.getSelectors<any>((state) => state.subscribers);

export default slice.reducer;
