import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { Header } from "./Header";
import { useAuth0 } from "@auth0/auth0-react";
export default function Home() {
  const { logout, loginWithPopup, isAuthenticated } = useAuth0();

  return (
    <>
      <Header title="Artorama Scanner" />
      <Container fluid>
        {(isAuthenticated && (
          <>
            <Button color="primary" tag={Link} to="/session/create" block>
              Créer une nouvelle session
            </Button>
            <Button color="primary" tag={Link} to="/session" outline block>
              Reprendre une session
            </Button>{" "}
            <Button
              color="danger"
              block
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              Log Out
            </Button>
          </>
        )) || (
          <Button color="primary" block onClick={() => loginWithPopup()}>
            Log In
          </Button>
        )}
      </Container>
    </>
  );
}
