// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, FormGroup, Input } from "reactstrap";

import { Header } from "./Header";
import moment from "moment";
import { getSessionsByStatus, fetchSessions } from "../store/sessionsSlice";
import { ApiResponse } from "./ApiRepsonse";
import { RootState } from "../store";

export default function SessionList() {
  const dispatch = useDispatch();

  const [status, setStatus] = React.useState<string>("active");

  const sessionsStatus = useSelector(
    (state: RootState) => state.sessions.status
  );
  const sessions = useSelector((state) => getSessionsByStatus(state, status));

  useEffect(() => {
    dispatch(fetchSessions());
  }, []);

  return (
    <>
      <Header title="Sessions" prevRoute="./" />

      <Container fluid>
        <FormGroup>
          <Input
            type="select"
            name="select"
            id="type"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active">actives</option>
            <option value="archived">archivées</option>
          </Input>
        </FormGroup>
      </Container>
      <div className="list-group list-group-flush">
        {sessionsStatus !== "succeeded" ? (
          <div className="text-center">
            <ApiResponse status={sessionsStatus} />
          </div>
        ) : (
          <>
            {sessions.map((session) => (
              <Link
                className="list-group-item"
                to={`/session/${session.id}`}
                key={session.id.toString()}
              >
                <small>{moment(session.created_at).format("DD/MM/YYYY")}</small>{" "}
                - {session.name}
              </Link>
            ))}
          </>
        )}
      </div>
    </>
  );
}
