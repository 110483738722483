// @ts-yescheck
import {
  createSelector,
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import {
  getSessions,
  getSession,
  putSession,
  deleteSession,
  postSession,
} from "../api/api";

import { Session } from "../api/api";

export const addNewSession = createAsyncThunk(
  "sessions/addNewSession",
  async (initialSession: any) => {
    return await postSession(initialSession);
  }
);

export const fetchSessions = createAsyncThunk(
  "sessions/fetchSessions",
  async () => {
    return await getSessions();
  }
);

export const fetchSession = createAsyncThunk(
  "sessions/fetchSession",
  async (id: string) => {
    return await getSession(id);
  }
);

export const updateSession = createAsyncThunk(
  "sessions/updateSession",
  async (params: { id: string; data: string }) => {
    return await putSession(params);
  }
);

export const removeSession = createAsyncThunk(
  "sessions/removeSession",
  async (id: string) => {
    return await deleteSession(id);
  }
);

interface SessionsState {
  status: string;
}

const sessionsAdapter = createEntityAdapter<Session>({
  selectId: (session) => session.id,
  //sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const sessionsInitialState: SessionsState = {
  status: "idle",
};

const slice = createSlice({
  name: "sessions",
  initialState: sessionsAdapter.getInitialState(sessionsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSessions.pending, (state: SessionsState) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchSessions.fulfilled,
      (state, { payload }: PayloadAction<Session[]>) => {
        sessionsAdapter.setAll(state, payload);
        state.status = "succeeded";
      }
    );
    builder.addCase(fetchSessions.rejected, (state) => {
      state.status = "failed";
    });

    builder.addCase(addNewSession.fulfilled, (state, { payload }) => {
      sessionsAdapter.addOne(state, payload);
    });

    builder.addCase(removeSession.fulfilled, (state, { payload }) => {
      sessionsAdapter.removeOne(state, payload);
    });

    builder.addCase(updateSession.pending, (state: SessionsState) => {
      state.status = "loading";
    });
    builder.addCase(updateSession.fulfilled, (state, { payload }) => {
      sessionsAdapter.updateOne(state, payload);
      state.status = "succeeded";
    });

    builder.addCase(fetchSession.pending, (state: SessionsState) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchSession.fulfilled,
      (state, { payload }: PayloadAction<Session>) => {
        sessionsAdapter.upsertOne(state, payload);
        state.status = "succeeded";
      }
    );
    builder.addCase(fetchSession.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export default slice.reducer;

export const {
  selectAll: selectAllSessions,
  selectById: selectSessionsById,
  selectIds: selectSessionsIds,
} = sessionsAdapter.getSelectors<any>((state) => state.sessions);

export const getSessionsByStatus = createSelector(
  [selectAllSessions, (state, status) => status],
  (sessions, status) => sessions.filter((session) => session.status === status)
);
