import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";

import sessionsReducer from "./sessionsSlice";
import listingsReducer from "./listingsSlice";
import eventsReducer from "./eventsSlice";
import subscribersReducer from "./subscribersSlice";

const reducer = combineReducers({
  sessions: sessionsReducer,
  listings: listingsReducer,
  events: eventsReducer,
  subscribers: subscribersReducer,
});

export const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware()],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
