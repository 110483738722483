import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getEvents, Event } from "../api/api";

export const fetchEvents = createAsyncThunk(
  "sessions/fetchEvents",
  async () => {
    const events = await getEvents();
    return events;
  }
);

interface EventsState {
  status: string;
}

const eventsInitialState: EventsState = {
  status: "idle",
};

const eventsAdapter = createEntityAdapter<Event>({
  selectId: (event) => event.id,
  sortComparer: (a, b) => a.description_fr.localeCompare(b.description_fr),
});

const slice = createSlice({
  name: "events",
  initialState: eventsAdapter.getInitialState(eventsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchEvents.fulfilled,
      (state, { payload }: PayloadAction<Event[]>) => {
        eventsAdapter.setAll(state, payload);
        state.status = "succeeded";
      }
    );
    builder.addCase(fetchEvents.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const {
  selectAll: selectAllEvents,
  selectById: selectEventsById,
  selectIds: selectEventsIds,
} = eventsAdapter.getSelectors<any>((state) => state.events);

export default slice.reducer;
