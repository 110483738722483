import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import QrReader from "react-qr-reader";
import md5 from "md5";

import { useHistory, useParams } from "react-router-dom";

import { Header } from "./Header";
import { RootState } from "../store";
import { CSVLink } from "react-csv";

import {
  fetchSession,
  updateSession,
  removeSession,
  selectSessionsById,
} from "../store/sessionsSlice";

import {
  fetchSubscribers,
  syncSubscribers,
  selectAllSubscribers,
  selectSubscribersById,
  setSubscriberDirection,
  selectTotalSubscribers,
} from "../store/subscribersSlice";
import { Subscriber } from "../api/api";

export default function Session() {
  const dispatch = useDispatch();
  let history = useHistory();

  let { id } = useParams<{ id: string }>();

  const [modal, setModal] = useState(false);
  const [errorScan, setErrorScan] = useState("");
  const [scanOpen, setScanOpen] = useState(false);
  const [currentSubscriberId, setCurrentSubscriberId] = useState<number>(0);
  const openModal = (subscriberId) => {
    setCurrentSubscriberId(subscriberId);
    setModal(true);
  };
  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(fetchSession(id));
    dispatch(fetchSubscribers(id));
  }, []);

  const session = useSelector((state) => selectSessionsById(state, id));
  const subscribers = useSelector((state) => selectAllSubscribers(state));

  const currentSubscriber = useSelector((state) =>
    selectSubscribersById(state, currentSubscriberId)
  );

  const tickets = useSelector((state) => selectTotalSubscribers(state));
  let peopleIn = 0;
  let peopleOut = 0;
  subscribers.forEach((e) => {
    peopleIn = peopleIn + e.people_in;
    peopleOut = peopleOut + e.people_out;
  });

  const status = useSelector((state: RootState) => state.subscribers.status);

  const handleChangeStatus = async (status) => {
    let message;
    if (status === "archive") {
      message = "Confirmer l'archivage de cette session ?";
    }
    if (status === "restore") {
      message = "Confirmer la restauration de cette session ?";
    }
    if (status === "delete") {
      message = "Confirmer la suppression de cette session ?";
    }

    let confirmation = await confirm({
      title: "Attention",
      message,
    });
    if (confirmation) {
      if (status === "archive") {
        await dispatch(updateSession({ id, data: "archived" }));
      }
      if (status === "restore") {
        await dispatch(updateSession({ id, data: "active" }));
      }
      if (status === "delete") {
        await dispatch(removeSession(id));
      }
      history.push("/session");
    }
    return false;
  };

  const getSyncSubscribers = async () => {
    await dispatch(syncSubscribers(id));
    dispatch(fetchSubscribers(id));
  };

  const setInOut = async (sessionId, subscriberId, direction) => {
    await dispatch(
      setSubscriberDirection({
        sessionId,
        subscriberId,
        direction,
      })
    );
  };

  function strtr(str, from, to) {
    let i = 0;
    let j = 0;
    let lenStr = 0;
    let lenFrom = 0;
    let fromTypeStr = false;
    let toTypeStr = false;
    let istr = "";
    let ret = "";
    let match = false;

    lenStr = str.length;
    lenFrom = from.length;
    fromTypeStr = typeof from === "string";
    toTypeStr = typeof to === "string";

    for (i = 0; i < lenStr; i++) {
      match = false;
      if (fromTypeStr) {
        istr = str.charAt(i);
        for (j = 0; j < lenFrom; j++) {
          if (istr == from.charAt(j)) {
            match = true;
            break;
          }
        }
      } else {
        for (j = 0; j < lenFrom; j++) {
          if (str.substr(i, from[j].length) == from[j]) {
            match = true;
            i = i + from[j].length - 1;
            break;
          }
        }
      }
      if (match) {
        ret += toTypeStr ? to.charAt(j) : to[j];
      } else {
        ret += str.charAt(i);
      }
    }

    return ret;
  }

  function hasharto(email) {
    return (
      "64537" +
      strtr(
        email,
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
        "2223334445556667777888999922233344455566677778889999"
      ) +
      "201465"
    );
  }

  // https://api.art-o-rama.fr/qrcode/64537{{ contact.EMAIL|phone2numeric }}201465/{{ contact.EMAIL }}

  const handleScan = (data) => {
    if (data) {
      const dataA = data.split("|");
      const hash = dataA[0];
      const mail = dataA[1];
      const isHashOk = hasharto(mail) === hash;

      if (isHashOk) {
        const foundSubscriber: Subscriber | undefined = subscribers.find(
          (s) => s.email === mail
        );
        if (foundSubscriber) {
          openModal(foundSubscriber.id);
          setScanOpen(false);
          window.navigator.vibrate(100);
          setErrorScan("");
        } else {
          setErrorScan("QRcode OK mais non invité sur cette liste");
          window.navigator.vibrate(100);
        }
      } else {
        setErrorScan(
          "QRcode inconnu " + md5(mail + "SALTORAMA") + " === " + hash
        );
        window.navigator.vibrate(100);
      }
    }
  };
  const handleError = (err) => {
    setErrorScan(err);
    window.navigator.vibrate([100, 100]);
  };
  const headers = [
    { label: "Prenom", key: "first_name" },
    { label: "Nom", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Direction", key: "direction" },
    { label: "date", key: "created_at" },
  ];

  return (
    <>
      {session && (
        <>
          <Header title={session.name} prevRoute="/session" />

          <Container fluid>
            <div className="text-right">
              {session.status === "active" && (
                <>
                  <Button
                    size="sm"
                    className="ml-3"
                    onClick={() => handleChangeStatus("archive")}
                  >
                    Archiver
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    size="sm"
                    className="ml-3"
                    onClick={() => getSyncSubscribers()}
                  >
                    {status === "loading" ? <Spinner size="sm" /> : "Sync"}
                  </Button>
                </>
              )}

              {session.status === "archived" && (
                <>
                  <CSVLink
                    data={session.logs ? session.logs : []}
                    headers={headers}
                  >
                    <Button size="sm">Logs</Button>
                  </CSVLink>
                  <Button
                    size="sm"
                    color="danger"
                    className="ml-3"
                    onClick={() => handleChangeStatus("delete")}
                  >
                    Supprimer
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    className="ml-3"
                    onClick={() => handleChangeStatus("restore")}
                  >
                    Restaurer
                  </Button>
                </>
              )}
            </div>
            <div>
              Nom de la session : <strong>{session.name}</strong>
            </div>

            <div>
              Type :{" "}
              <strong>
                {session.type === "listing"
                  ? "liste SendInBlue"
                  : "évenement VIP"}
              </strong>
            </div>
            <div>
              {session.type === "listing" ? "Liste" : "Evenement"} :{" "}
              <strong>
                {session.listing?.name} {session.event?.name}
              </strong>
            </div>
            <div>
              Status : <strong>{session.status}</strong>{" "}
            </div>
            <div>
              Nombre de tickets : <strong>{tickets}</strong>
            </div>

            {session.type === "listing" && (
              <div>
                Nombre de personne par entrée :{" "}
                <strong>{session.people}</strong>
              </div>
            )}
            <div>
              Personnes IN / OUT :{" "}
              <strong className="text-success">{peopleIn}</strong> /{" "}
              <strong className="text-danger">{peopleOut}</strong>
            </div>

            <div className="my-2">
              <Button
                block
                color="primary"
                onClick={() => {
                  setScanOpen(!scanOpen);
                  setErrorScan("");
                }}
              >
                {scanOpen ? "fermer" : "Lecteur QrCode"}
              </Button>
              {scanOpen && (
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%" }}
                />
              )}
              {errorScan && <p className="alert danger my-3">{errorScan}</p>}
            </div>
            {/* <div className="my-2">
              <Button block color="secondary" outline>
                Logs
              </Button>
            </div> */}
          </Container>
        </>
      )}
      {status === "loading" && (
        <div className="text-center m-4">
          <Spinner />
        </div>
      )}
      {status === "succeeded" && (
        <Table size="sm" striped className="subscribersList">
          <thead>
            <tr>
              <th>Nom / Prénom / Email</th>
              <th className="text-center">Places</th>
              <th className="text-center">Sortis</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((subscriber, key) => (
              <React.Fragment key={key}>
                <tr
                  onClick={() => openModal(subscriber.id)}
                  className={
                    subscriber.people === subscriber.people_in
                      ? "danger"
                      : subscriber.people_in > 0
                      ? "warning"
                      : ""
                  }
                >
                  <td>
                    <span className="font-weight-bold">
                      {subscriber.last_name ? subscriber.last_name : "[X]"}{" "}
                    </span>
                    {subscriber.first_name ? subscriber.first_name : "[X]"}
                    <br />
                    <small>{subscriber.email}</small>
                  </td>
                  <td className="text-center">
                    {subscriber.people_in}/{subscriber.people}
                  </td>
                  <td className="text-center">
                    {subscriber.people_out}/{subscriber.people}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
      <Modal isOpen={modal} toggle={toggle}>
        {currentSubscriber && (
          <>
            <ModalHeader toggle={toggle}>
              {currentSubscriber.last_name} {currentSubscriber.first_name}
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <h2>
                  {currentSubscriber.last_name
                    ? currentSubscriber.last_name
                    : "[X]"}{" "}
                  {currentSubscriber.first_name
                    ? currentSubscriber.first_name
                    : "[X]"}
                </h2>
                <p>{currentSubscriber.email}</p>
                <p className="h1">
                  <Button
                    size="lg"
                    disabled={currentSubscriber.people_in === 0}
                    color={
                      currentSubscriber.people_in === 0 ? "secondary" : "danger"
                    }
                    onClick={() =>
                      setInOut(
                        currentSubscriber.session_id,
                        currentSubscriber.id,
                        "out"
                      )
                    }
                    className="mr-2"
                  >
                    OUT
                  </Button>
                  {currentSubscriber.people_in} / {currentSubscriber.people}
                  <Button
                    size="lg"
                    color={
                      currentSubscriber.people_in === currentSubscriber.people
                        ? "secondary"
                        : "success"
                    }
                    onClick={() =>
                      setInOut(
                        currentSubscriber.session_id,
                        currentSubscriber.id,
                        "in"
                      )
                    }
                    className="ml-2"
                    disabled={
                      currentSubscriber.people_in === currentSubscriber.people
                    }
                  >
                    IN
                  </Button>
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Fermer
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
}
