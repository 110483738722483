import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./components/Home";
import SessionList from "./components/SessionList";
import Session from "./components/Session";
import SessionCreate from "./components/SessionCreate";
import ProtectedRoute from "./auth/protected-route";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./components/Loading";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact children={<Home />} />
        <ProtectedRoute
          path="/session/create"
          exact
          component={SessionCreate}
        />

        <ProtectedRoute path="/session/:id" exact component={Session} />
        <ProtectedRoute path="/session" exact component={SessionList} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
