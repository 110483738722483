import React from "react";
import { Container, Spinner } from "reactstrap";

export const Loading = () => {
  return (
    <Container className="m-5 text-center">
      <Spinner />
    </Container>
  );
};
