import axios from "axios";

//const apiBaseUrl = "https://api.art-o-rama.fr/api";

const apiBaseUrl = process.env.REACT_APP_API_URL;

console.log(process.env.REACT_APP_API_URL);

export interface Listing {
  id: number;
  name: string;
  folderId: number;
  total_subscribers: number;
}

export interface Log {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  direction: string;
  session_id: number;
  created_at: string;
  updated_at: string;
}
export interface Session {
  id: number;
  name: string;
  type: string; // list or ??
  listing_id?: number;
  people?: number;
  listing?: {
    id: number;
    name: string;
  };
  event_id?: number;
  event?: {
    id: number;
    name: string;
  };
  status?: string; // active or ??
  created_at: Date;
  logs: Log[];
}

export interface Event {
  id: number;
  date: Date;
  name: string;
  schedule_fr: string;
  schedule_en: string;
  description_fr: string;
  description_en: string;
  max_seats: number;
  subscription_open: number;
  created_at: Date;
}

export interface Subscriber {
  id: number;
  first_name: string;
  last_name: string;
  people: number;
  people_in: number;
  people_out: number;
  email: string;
  session_id: string;
}

export async function postSession(initialSession: any): Promise<Session> {
  const url = `${apiBaseUrl}/session`;

  try {
    const response = await axios.post(url, initialSession);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getSessions(): Promise<Session[]> {
  const url = `${apiBaseUrl}/session`;

  try {
    const response = await axios.get<Session[]>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getSession(sessionId: string): Promise<Session> {
  const url = `${apiBaseUrl}/session/${sessionId}`;
  try {
    const response = await axios.get<Session>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function putSession(params: {
  id: string;
  data: string;
}): Promise<any> {
  const url = `${apiBaseUrl}/session/${params.id}`;
  try {
    const response = await axios.put(url, {
      params: params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteSession(id: string): Promise<any> {
  const url = `${apiBaseUrl}/session/${id}`;
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getListings(): Promise<Listing[]> {
  const url = `${apiBaseUrl}/listing`;

  try {
    const response = await axios.get<Listing[]>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getEvents(): Promise<Event[]> {
  const url = `${apiBaseUrl}/event`;

  try {
    const response = await axios.get<Event[]>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getSubscribers(sessionId: string): Promise<Subscriber[]> {
  const url = `${apiBaseUrl}/session/${sessionId}/subscriber`;

  try {
    const response = await axios.get<Subscriber[]>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function postSubscriberDirection(
  sessionId: string,
  subscriberId: string,
  direction: string
): Promise<Subscriber> {
  const url = `${apiBaseUrl}/session/${sessionId}/subscriber/${subscriberId}/${direction}`;

  try {
    const response = await axios.post<Subscriber>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getSyncSubscribers(
  sessionId: string
): Promise<Subscriber[]> {
  const url = `${apiBaseUrl}/session/${sessionId}/sync`;

  try {
    const response = await axios.get<Subscriber[]>(url);
    return response.data;
  } catch (err) {
    throw err;
  }
}
