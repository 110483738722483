import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Alert, Button, Spinner } from "reactstrap";

type HeaderProps = {
  status: string;
};

export function ApiResponse({ status }: HeaderProps) {
  if (status === "failed") return <Alert color="danger">Erreur</Alert>;
  switch (status) {
    case "failed":
      return <Alert color="danger">Erreur</Alert>;
    case "loading":
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    default:
      return <Alert color="primary">{status}</Alert>;
  }
}
