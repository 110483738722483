import { useHistory, useLocation } from "react-router";

type HeaderProps = {
  prevRoute?: string;
  title: string;
};

export const Back = ({ prevRoute }) => {
  let history = useHistory();
  const location = useLocation();
  if (location.pathname === "/") {
    return null;
  }

  function handlePrev() {
    if (prevRoute) {
      history.push(prevRoute);
    } else {
      history.goBack();
    }
  }

  return (
    <>
      <div className="prev" onClick={handlePrev}>
        ⇽
      </div>
    </>
  );
};

export function Header({ title, prevRoute }: HeaderProps) {
  return (
    <div className="header">
      <Back prevRoute={prevRoute} />
      {title}
    </div>
  );
}
